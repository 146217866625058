import React, { useState, useEffect } from "react";
import moment from "moment";

import swal from "sweetalert";
import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/th";
import { api, header_token } from "../../config";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const SaleBillIndex = () => {
  const { push } = useHistory();
  const [loading, setloading] = useState(false);

  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [total_sales, settotal_sales] = useState(0);
  const [total_commission_sales, settotal_commission_sales] = useState(0);
  const [total_shipping, settotal_shipping] = useState(0);
  const [total_commission_shipping, settotal_commission_shipping] = useState(0);
  const [total_order, settotal_order] = useState(0);

  const [filter_area] = useState("");
  const [filter_id, setfilter_id] = useState("");
  const [filter_name] = useState("");
  const [filter_from, setfilter_from] = useState(new Date());
  const [filter_to, setfilter_to] = useState(new Date());

  const getDataInit = async () => {
    setloading(true);
    try {
      let start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        end_date: end,
        order_no: "",
        start_date: start,
        status: [5],
      };

      // console.log("send", send_data);
      // let { data } = await Axios.post(
      //   `${api}/admin/order/filter?page=${1}&limit=${99999999}&sort=${-1}`,
      //   send_data,
      //   header_token
      // );

      let { data } = await Axios.post(
        `${api}/order/history/restaurant/filter?page=${1}&limit=${9999999999}&sort=${-1}`,
        send_data,
        header_token
      );

      // console.log("data", data);
      if (data.orders !== null) {
        let total_sales = 0;
        let total_commission_sales = 0;
        let total_shipping = 0;
        let total_commission_shipping = 0;

        data.orders.forEach((value) => {
          total_sales += value.food_cost;
          total_shipping += value.shipping_cost;

          total_commission_sales +=
            value.food_cost * (value.percentage_gp / 100);

          total_commission_shipping +=
            value.shipping_cost * (value.percentage_rider_charge / 100);
        });

        settotal_sales(total_sales);
        settotal_commission_sales(total_commission_sales);
        settotal_shipping(total_shipping);
        settotal_commission_shipping(total_commission_shipping);
        settotal_order(data.meta.total_count);

        // setdata(data.orders);
      } else {
        setpage_count(0);
        settotal_count(0);
        setdata([]);
        settotal_sales(0);
        settotal_commission_sales(0);
        settotal_shipping(0);
        settotal_commission_shipping(0);
        settotal_order(0);
      }
    } catch (error) {
      // console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const getData = async () => {
    setloading(true);
    try {
      let start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        end_date: end,
        order_no: filter_id,
        restaurant_name: filter_name,
        start_date: start,
        status: [5],
      };

      let { data } = await Axios.post(
        `${api}/order/history/restaurant/filter?page=${1}&limit=${9999999999}&sort=${-1}`,
        send_data,
        header_token
      );

      // console.log("data", data);
      if (data.orders !== null) {
        // let total_sales = 0;
        // let total_commission_sales = 0;
        // let total_shipping = 0;
        // let total_commission_shipping = 0;
        // let total_order = 0;

        // data.orders.forEach((value) => {
        //   total_order++;
        //   total_sales += value.food_cost;
        //   total_shipping += value.shipping_cost;

        //   total_commission_sales +=
        //     value.food_cost * (value.percentage_gp / 100);

        //   total_commission_shipping +=
        //     value.shipping_cost * (value.percentage_rider_charge / 100);
        // });

        // settotal_sales(total_sales);
        // settotal_commission_sales(total_commission_sales);
        // settotal_shipping(total_shipping);
        // settotal_commission_shipping(total_commission_shipping);
        // settotal_order(data.meta.total_count);

        let cal_page_count = Math.ceil(data.meta.total_count / limit);
        setpage_count(cal_page_count);
        settotal_count(data.meta.total_count);

        setdata(data.orders);
      } else {
        setpage_count(0);
        settotal_count(0);
        setdata([]);
        // settotal_sales(0);
        // settotal_commission_sales(0);
        // settotal_shipping(0);
        // settotal_commission_shipping(0);
        // settotal_order(0);
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  useEffect(() => {
    getDataInit();
    getData();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getDataInit();
    getData();
  }, [filter_from, filter_to, filter_area]);

  useEffect(() => {
    getData();
  }, [current_page]);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_id, filter_name]);

  // useEffect(() => {
  //   getDataInit();
  //   getData();
  //   setcurrent_page(1);
  // }, [filter_from, filter_to]);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-3">ยอดขาย</h4>
            <div className="d-none d-md-flex mt-2">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าหลัก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">ยอดขาย</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <h4 className="mb-4  ml-3" style={{ marginTop: 0 }}>
          {moment().format("LL")}
        </h4>

        <div className="ml-auto mr-2 mb-2" style={{ float: "right" }}>
          <p className="tx-13 text-muted mb-2">ค้นหาจากช่วงเวลา</p>
          <div className="InputFromTo ">
            <DayPickerInput
              value={filter_from}
              onDayChange={(e) => {
                setfilter_from(e);
                if (!filter_to) {
                  setfilter_to(e);
                }
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
            />{" "}
            ถึง{" "}
            <span className="InputFromTo-to">
              <DayPickerInput
                value={filter_to}
                onDayChange={(e) => {
                  setfilter_to(e);
                  if (!filter_from) {
                    setfilter_from(e);
                  }
                }}
                overlayComponent={CustomOverlay}
                placeholder={"วัน/เดือน/ปี"}
                format="L"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  locale: "th",
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              {/* <div className="row mt-4 mb-4"> */}
              <div className="row mt-4">
                <div className="col-12">
                  <div className="d-flex flex-wrap mb-2">
                    <div className="mr-4">
                      <h2 className="mb-1 text-success ">
                        ฿{total_sales.toLocaleString()}
                      </h2>
                      <p className="text-muted mb-0 tx-13">ยอดขายทั้งหมด</p>
                    </div>

                    <div className="mr-4">
                      <h2 className="mb-1  ">
                        ฿
                        {total_commission_sales.toLocaleString("en", {
                          maximumFractionDigits: 0,
                        })}
                      </h2>
                      <p className="text-muted mb-0 tx-13">
                        ค่าคอมมิชชั่นยอดขาย
                      </p>
                    </div>

                    <div className="mr-4">
                      <h2 className="mb-1  ">
                        ฿{total_shipping.toLocaleString()}
                      </h2>
                      <p className="text-muted mb-0 tx-13">ค่าจัดส่ง</p>
                    </div>

                    <div className="mr-4">
                      <h2 className="mb-1  ">
                        ฿
                        {total_commission_shipping.toLocaleString("en", {
                          maximumFractionDigits: 0,
                        })}
                      </h2>
                      <p className="text-muted mb-0 tx-13">
                        ค่าคอมมิชชั่นค่าจัดส่ง
                      </p>
                    </div>

                    <div className="mr-4">
                      <h2 className="mb-1  ">{total_order.toLocaleString()}</h2>
                      <p className="text-muted mb-0 tx-13">จำนวนออเดอร์</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-12 mb-2 mt-4">
                  <div className="form-row align-items-center mb-2">
                    <h4 className="mt-4 ml-1">ทั้งหมด {total_count} รายการ</h4>

                    <div className="col-sm-2 mr-1 ml-auto">
                      <span>Order Id</span>
                      <input
                        type="text"
                        className="form-control- global_filter"
                        id="global_filter"
                        placeholder="ออเดอร์ ID"
                        value={filter_id}
                        onChange={(e) => setfilter_id(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="sticky-div">
                    <table
                      id="dataTable"
                      className="table sticky-table"
                      style={{ width: "100%" }}
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          <th style={{ width: 150 }} className="sticky-head">
                            ออเดอร์ ID
                          </th>
                          <th style={{ width: 200 }}>ชื่อร้าน</th>
                          <th style={{ width: 200 }}>พื้นที่</th>
                          <th style={{ width: 80 }}>ค่าอาหาร</th>
                          <th style={{ width: 100 }}>ค่าคอมมิชชั่น</th>
                          <th style={{ width: 100 }}>ค่าจัดส่ง</th>
                          <th style={{ width: 130 }}>ค่าคอมมิชชั่นค่าส่ง</th>
                          <th style={{ width: 150 }}>วันที่</th>
                          <th style={{ width: 100 }}>ชำระ</th>
                          <th style={{ width: 130 }}>ดำเนินการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td className="sticky-col">
                                <a
                                  tabIndex={0}
                                  onClick={() =>
                                    push(`/order/detail/${value.id}`)
                                  }
                                >
                                  {value.order_no}
                                </a>
                              </td>
                              <td>{value.restaurant.name_th}</td>
                              <td> {value.restaurant.area.name_th}</td>
                              <td>฿{value.food_cost}</td>
                              <td>
                                ฿
                                {(
                                  value.food_cost *
                                  (value.percentage_gp / 100)
                                ).toLocaleString("en", {
                                  maximumFractionDigits: 0,
                                })}
                              </td>
                              <td>
                              ฿
                              {data.is_promotion_service
                                ? value.shipping_cost_promotion
                                : value.shipping_cost}
                            </td>
                              <td>
                                ฿
                                {(
                                  value.shipping_cost *
                                  (value.percentage_rider_charge / 100)
                                ).toLocaleString("en", {
                                  maximumFractionDigits: 0,
                                })}
                              </td>
                              <td>
                                {moment(value.created_at).format("DD/MM/YYYY")}{" "}
                                {moment(value.created_at).format("LT")}
                              </td>
                              <td>{value.payment_method}</td>
                              <td>
                                <button
                                  className="btn btn-outline-primary btn-sm mb-1"
                                  onClick={() =>
                                    push(`/order/detail/${value.id}`)
                                  }
                                >
                                  รายละเอียด
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-2" style={{ float: "right" }}>
                    <ReactPaginate
                      previousLabel={"← Previous"}
                      nextLabel={"Next →"}
                      breakLabel={<span className="gap">...</span>}
                      pageCount={page_count}
                      onPageChange={(e) => setcurrent_page(e.selected + 1)}
                      forcePage={current_page - 1}
                      containerClassName={"pagination"}
                      previousLinkClassName={"previous_page"}
                      nextLinkClassName={"next_page"}
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
