import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Select from "react-select";
import "moment/locale/th";
import moment from "moment";
import {
  api,
  img_url,
  header_token,
  convertMoney,
  google_api_key,
} from "../../config";
import Axios from "axios";
import swal from "sweetalert";
import { ModalShowImage } from "../../ModalShowImage";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const order_status = [
  { value: 1, label: "ออเดอร์ใหม่" },
  { value: 4, label: "กำลังจัดส่ง" },
  { value: 5, label: "ส่งสำเร็จ" },
  { value: 0, label: "ยกเลิก" },
  { value: 6, label: "ส่งไม่สำเร็จ" },
  { value: 3, label: "กำลังดำเนินการ" },
  { value: 2, label: "พนักงานส่งรับงาน" },
];

const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${google_api_key}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  // console.log('re-render');
  return (
    <GoogleMap defaultCenter={props.point} zoom={17} center={props.point}>
      <Marker position={props.point} draggable={false} />
    </GoogleMap>
  );
});

export const OrderCarDetail = () => {
  const { id } = useParams();

  const [img, setimg] = useState("");
  const [point, setpoint] = useState({ lat: 13.674351, lng: 100.517556 });

  const [data, setdata] = useState({
    restaurant: {
      location: {
        latitude: 16.409936252252667,
        longitude: 103.06710188750003,
        address: "",
        province_id: "",
        district_id: "",
        sub_district_id: "",
        province: {
          id: "",
          code: "",
          name_th: "",
          name_en: "",
          short_name_en: "",
          short_name_th: "",
        },
        district: {
          id: "",
          code: "",
          name_th: "",
          name_en: "",
        },
        sub_district: {
          id: "",
          zip_code: "",
          name_en: "",
          name_th: "",
        },
      },
    },
    user: {},
    to: {
      latitude: 16.409936252252667,
      longitude: 103.06710188750003,
      address: "",
      province_id: "",
      district_id: "",
      sub_district_id: "",
      province: {
        id: "",
        code: "",
        name_th: "",
        name_en: "",
        short_name_en: "",
        short_name_th: "",
      },
      district: {
        id: "",
        code: "",
        name_th: "",
        name_en: "",
      },
      sub_district: {
        id: "",
        zip_code: "",
        name_en: "",
        name_th: "",
      },
    },
    rider: { id: "", employee_type: {}, team: {} },
  });
  const [log, setlog] = useState([]);
  const [menu, setmenu] = useState([]);
  const [addon_option, setaddon_option] = useState([]);
  const [add_on_option, setadd_on_option] = useState({});
  const [select_order, setselect_order] = useState({});
  const [order_option, setorder_option] = useState([]);
  const [rider_option, setrider_option] = useState([]);
  const [select_rider, setselect_rider] = useState({});

  const [status, setstatus] = useState({});

  const getOrder = async () => {
    try {
      let { data } = await Axios.get(`${api}/admin/order/${id}`, header_token);

      let tmp = [];
      // console.log("data", data);
      if (data.car_service_addons) {
        if (data.car_service_addons.length > 0) {
          data.car_service_addons.forEach((value) => {
            let _cost = value.is_promotion
              ? value.promotion_price
              : value.price;
            tmp.push({
              id: value.id,
              name: { value: value.id, label: value.name },
              price: _cost,
              remark: value.remark,
              edit: false,
              new: false,
            });
          });
        }
      }

      setmenu(tmp);

      let _tmp = { ...data };

      if (data.rider === null) {
        _tmp.rider = { id: "", employee_type: {}, team: {} };
      }
      getMenu(data.car_service_id, tmp);
      setdata(_tmp);

      setstatus(
        order_status.filter(
          (e) => parseInt(e.value) === parseInt(data.status)
        )[0]
      );
    } catch (error) {
      console.log(error);
      swal(`${error.response}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getOrderLog = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/admin/order/${id}/log`,
        header_token
      );

      // console.log("getOrderLog", data);
      setlog(data);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getMenu = async (car_service_id, addon_selected) => {
    try {
      let { data } = await Axios.get(
        `${api}/car-service/${car_service_id}/addon`
      );
      let tmp = [];
      // console.log("getMenu", data);
      data.forEach((value) => {
        tmp.push({
          id: value.id,
          value: value.id,
          label: value.name,
          price: value.price,
          percentage_commission: value.percentage_commission,
        });
      });
      tmp.push({
        id: "",
        value: "",
        label: "อื่นๆ",
        price: 0,
      });

      setaddon_option(tmp);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const addMenu = async (data) => {
    // console.log(data);
    try {
      let res = await Axios.post(
        `${api}/admin/order/${id}/car-addon/`,
        data,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getOrder();
        getOrderLog();
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
  };

  const deleteMenu = async (addon_id) => {
    // console.log(data);
    try {
      let res = await Axios.delete(
        `${api}/admin/order/${id}/car-addon/${addon_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getOrder();
        getOrderLog();

        return true;
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
      return false;
    }
  };

  const cancelOrder = async (order_id) => {
    try {
      let res = await Axios.get(
        `${api}/admin/order/${order_id}/cancel`,
        header_token
      );
      if (res.status === 200) {
        getOrder();
        swal("ยกเลิกเสร็จสิ้น !!", {
          icon: "success",
        });
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const assignOrder = async (rider_id, order_id) => {
    try {
      let send_data = {
        order_id: order_id,
        rider_id: rider_id,
      };

      let res = await Axios.post(
        `${api}/admin/order/assign/`,
        send_data,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getDataOnlineRider = async () => {
    try {
      let send_data = {
        status: 1,
        // team_id: filter_team.value,
        role: "rider",
        available: "online",
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${1}&limit=${9999999}`,
        send_data,
        header_token
      );
      // console.log(data);
      let tmp = [];
      data.users.map((val) => {
        tmp.push({ value: val.id, label: `${val.user_id} ${val.full_name}` });
      });

      setrider_option(tmp);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    // console.log(id);
    getOrder();
    getOrderLog();
    getDataOnlineRider();
  }, []);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">
              Order No.{data.order_no}
            </h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                รายการสั่งซื้อ
              </p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                Order No.{data.order_no}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">วันที่/เวลา</h6>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="input-group  d-flex align-items-center col-8">
                  <div className="input-group-addon">
                    <span className="text-muted tx-13">ออเดอร์วันที่</span>
                    <div className="input-group mb-3">
                      <input
                        id="add_name"
                        className="form-control"
                        name="add_name"
                        type="text"
                        // value={""}
                        style={{ width: 200, borderRight: 0 }}
                        value={`${moment(
                          data.created_at,
                          "YYYY/MM/DD h:mm:ss a"
                        ).format("DD/MM/YYYY")} ${moment(
                          data.created_at,
                          "YYYY/MM/DD h:mm:ss a"
                        ).format("LT")}
                        `}
                        readOnly
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{
                            backgroundColor: "#F7F9FC",
                            borderTopColor: "rgb(227, 231, 237)",
                            borderRightColor: "rgb(227, 231, 237)",
                            borderBottomColor: "rgb(227, 231, 237)",
                            borderLeft: 0,
                          }}
                        >
                          <i
                            className="fa fa-calendar-o"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group-addon mx-2 mt-3">-</div>
                  <div className="input-group-addon">
                    <span className="text-muted tx-13">วันที่ส่ง</span>

                    <div className="input-group mb-3">
                      <input
                        id="add_name"
                        className="form-control"
                        name="add_name"
                        type="text"
                        // value={""}
                        style={{ width: 200, borderRight: 0 }}
                        value={
                          data.delivered_date
                            ? `${moment(
                                data.delivered_date,
                                "YYYY/MM/DD h:mm:ss a"
                              ).format("DD/MM/YYYY")} ${moment(
                                data.delivered_date,
                                "YYYY/MM/DD h:mm:ss a"
                              ).format("LT")}
                            `
                            : data.due_date
                            ? `${moment(
                                data.due_date,
                                "YYYY/MM/DD h:mm:ss a"
                              ).format("DD/MM/YYYY")} ${moment(
                                data.due_date,
                                "YYYY/MM/DD h:mm:ss a"
                              ).format("LT")}
                            `
                            : ""
                        }
                        readOnly
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{
                            backgroundColor: "#F7F9FC",
                            borderTopColor: "rgb(227, 231, 237)",
                            borderRightColor: "rgb(227, 231, 237)",
                            borderBottomColor: "rgb(227, 231, 237)",
                            borderLeft: 0,
                          }}
                        >
                          <i
                            className="fa fa-calendar-o"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group-addon ml-3">
                    <span className="text-muted tx-13">ประเภทรถ</span>
                    <h5>{data.car_service_name}</h5>
                  </div>
                  <div className="input-group-addon ml-3">
                    <span className="text-muted tx-13">ประเภท</span>
                    <h5>{data.type === 0 ? "ออเดอร์ล่วงหน้า" : "ส่งทันที"}</h5>
                  </div>
                </div>

                <span className="ml-auto mr-3">
                  <span className="tx-13 text-muted">สถานะ</span>
                  <Select
                    value={status}
                    options={order_status}
                    placeholder={"สถานะ.."}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        width: 150,
                      }),
                    }}
                    // isDisabled
                    // onChange={() => {
                    //   console.log(menu);
                    // }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">สถานที่จัดส่ง</h6>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="container_timeline">
                  <div className="timeline-block timeline-block-right mb-4">
                    <div className="marker active" style={{ zIndex: 1 }}>
                      <i className="fa fa-map-marker " aria-hidden="true"></i>
                    </div>
                    <div className="timeline-content border-bottom pb-4">
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col">
                              <span className="text-muted tx-13">ต้นทาง</span>
                              <br />
                              {data.car_service_start_point
                                ? data.car_service_start_point.contact_name
                                : null}
                            </div>
                            <div className="col">
                              <span className="text-muted tx-13">ที่อยู่</span>
                              <br />
                              {data.car_service_start_point
                                ? data.car_service_start_point.address
                                : null}{" "}
                            </div>
                            <div className="col">
                              <span className="text-muted tx-13">
                                เบอร์โทรศัพท์
                              </span>
                              <br />
                              {data.car_service_start_point
                                ? data.car_service_start_point.phone_number
                                : null}
                            </div>
                            <div className="col">
                              <span className="text-muted tx-13">เครดิต</span>
                              <br />
                              {data.user.point
                                ? `฿${convertMoney(data.restaurant.credit)}`
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {data.car_service_destinations
                    ? data.car_service_destinations.map((value, idx) => (
                        <div
                          className="timeline-block timeline-block-right "
                          key={idx}
                        >
                          <div className="marker active" style={{ zIndex: 1 }}>
                            <i
                              className="fa fa-map-marker "
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="timeline-content border-bottom pb-5 ">
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col">
                                    <span className="text-muted tx-13">
                                      ปลายทาง
                                    </span>
                                    <br />
                                    {value.contact_name}
                                    <br />
                                    <span className="tx-13 text-muted">
                                      {data.user.user_id}
                                    </span>
                                  </div>
                                  <div className="col">
                                    <span className="text-muted tx-13">
                                      ที่อยู่
                                    </span>
                                    <br />
                                    {value.address}
                                  </div>
                                  <div className="col">
                                    <span className="text-muted tx-13">
                                      เบอร์โทรศัพท์
                                    </span>
                                    <br />
                                    {value.phone_number}
                                  </div>
                                  <div className="col">
                                    <span className="text-muted tx-13">
                                      คะแนน
                                    </span>
                                    <br />
                                    {data.user.point
                                      ? `฿${convertMoney(
                                          data.restaurant.credit
                                        )}`
                                      : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">พนักงานส่ง</h6>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="ml-2" style={{ width: 80 }}>
                  <div className="thumbnail">
                    <img
                      className="lazy img-responsive cursor-img"
                      data-toggle="modal"
                      data-target=".show-img"
                      onClick={() => {
                        setimg(
                          data.rider.picture === ""
                            ? "/images/no-img.jpg"
                            : `${img_url}${data.rider.picture}`
                        );
                      }}
                      src={
                        data.rider.picture
                          ? `${img_url}${data.rider.picture}`
                          : "/images/no-img.jpg"
                      }
                    />
                  </div>
                </div>
                <div className="col">
                  <span className="text-muted tx-13">ชื่อนามสกุล</span>
                  <br />
                  <Link to={`/rider/history/${data.rider.id}`}>
                    <span>{data.rider.full_name}</span>
                  </Link>
                  <br />
                  <span className="tx-13 text-muted">{data.rider.user_id}</span>
                </div>
                <div className="col">
                  <span className="text-muted tx-13">เบอร์โทรศัพท์</span>
                  <br />
                  <span>{data.rider.phone_number}</span>
                </div>
                <div className="col">
                  <span className="text-muted tx-13">เครดิต</span>
                  <br />
                  <span>
                    ฿{data.rider.credit ? convertMoney(data.rider.credit) : 0}
                  </span>
                </div>
                <div className="col">
                  <span className="text-muted tx-13">ประเภท</span>
                  <br />
                  <span> {data.rider.employee_type.name_th}</span>
                </div>
                <div className="col">
                  <span className="text-muted tx-13">ทีมงาน</span>
                  <br />
                  <span> {data.rider.team.name_th}</span>
                </div>

                {data.signature_image ? (
                  <div className="col">
                    <span className="text-muted tx-13">ลายเซ็น</span>
                    {/* <br /> */}
                    <div
                      className="thumbnail cursor-img"
                      data-toggle="modal"
                      data-target=".show-img"
                      onClick={() => {
                        setimg(
                          data.signature_image === ""
                            ? "/images/no-img.jpg"
                            : `${img_url}${data.signature_image}`
                        );
                      }}
                    >
                      <img
                        className="lazy img-responsive"
                        src={
                          data.signature_image === ""
                            ? "/images/no-img.jpg"
                            : `${img_url}${data.signature_image}`
                        }
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">สถานะการจัดส่ง</h6>
          <div className="card">
            <div className="card-body">
              <table id="" className="table">
                <thead>
                  <tr>
                    <th width="300">สถานะ</th>
                    <th width="200">ละติจูด</th>
                    <th width="200">ลองจิจูด</th>
                    <th width="150">วันที่</th>
                    <th width="150">เวลา</th>
                  </tr>
                </thead>
                <tbody>
                  {log.map((value, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="name">{value.remark}</td>
                        <td>
                          <div
                            className="cursor-img"
                            data-toggle="modal"
                            data-target=".show-map"
                            onClick={() =>
                              setpoint({
                                lat: value.latitude,
                                lng: value.longitude,
                              })
                            }
                          >
                            <u>{value.latitude}</u>
                          </div>
                        </td>
                        <td>
                          <div
                            className="cursor-img"
                            data-toggle="modal"
                            data-target=".show-map"
                            onClick={() =>
                              setpoint({
                                lat: value.latitude,
                                lng: value.longitude,
                              })
                            }
                          >
                            <u>{value.longitude}</u>
                          </div>
                        </td>
                        <td className="unit">{`${moment(
                          value.created_at,
                          "YYYY/MM/DD h:mm:ss a"
                        ).format("DD/MM/YYYY")}`}</td>
                        <td className="price">
                          {moment(
                            value.created_at,
                            "YYYY/MM/DD h:mm:ss a"
                          ).format("LT")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">ค่าขนส่ง</h6>
          <div className="card">
            <div className="card-body">
              {/* <div className="table-responsive"> */}
              <div className="content">
                <table id="" className="table">
                  <thead>
                    <tr>
                      <th width="600">บริการเสริม </th>
                      {/* <th width="400">add-on</th> */}
                      {/* <th width="80">จำนวน</th> */}
                      <th width="100">ราคา</th>
                      <th width="150">หมายเหตุ</th>
                      <th width="50">Save</th>
                      <th width="50">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menu.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td className="name">
                            {value.edit === true ? (
                              <Select
                                value={value.name}
                                options={addon_option}
                                placeholder={"เมนู.."}
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    // width: 100,
                                  }),
                                }}
                                // isDisabled={value.new ? false : true}
                                onChange={async (e) => {
                                  //   console.log(e);
                                  let tmp = [...menu];
                                  tmp[index].id = e.value;
                                  tmp[index].name = e;
                                  tmp[index].price = e.price;

                                  await setmenu(tmp);
                                }}
                              />
                            ) : (
                              value.name.label
                            )}
                          </td>
                          <td>
                            {value.edit === true ? (
                              <input
                                type="text"
                                className="form-control"
                                value={value.price}
                                onChange={(e) => {
                                  let tmp = [...menu];
                                  tmp[index].price = parseInt(e.target.value)
                                    ? parseInt(e.target.value)
                                    : 0;
                                  setmenu(tmp);
                                }}
                                disabled={
                                  value.name.label === "อื่นๆ" ? false : true
                                }
                              />
                            ) : value.price ? (
                              value.price
                            ) : (
                              0
                            )}
                          </td>
                          <td>
                            {value.edit === true ? (
                              <input
                                type="text"
                                className="form-control"
                                value={value.remark}
                                onChange={(e) => {
                                  let tmp = [...menu];
                                  tmp[index].remark = e.target.value;
                                  setmenu(tmp);
                                }}
                              />
                            ) : (
                              value.remark
                            )}
                          </td>
                          {data.status === 1 ||
                          data.status === 2 ||
                          data.status === 3 ||
                          data.status === 4 ||
                          1 === 1 ? (
                            <>
                              <td className="edit">
                                {value.edit === true ? (
                                  <i
                                    style={{ cursor: "pointer" }}
                                    className="fa tx-20 mt-1 ml-1 text-muted fa-save"
                                    onClick={() => {
                                      let tmp = [...menu];

                                      // console.log("value", value);
                                      if (tmp[index].new === true) {
                                        let send_data = {
                                          addon_id: value.id,
                                        };

                                        // console.log(send_data);
                                        addMenu(send_data);
                                      }
                                      tmp[index].edit = !tmp[index].edit;
                                      tmp[index].new = false;

                                      setmenu(tmp);
                                    }}
                                  ></i>
                                ) : null}
                              </td>
                              <td className="trash">
                                <i
                                  // style={{ cursor: "pointer" }}
                                  className={`${
                                    value.edit === true
                                      ? "fa fa-ban"
                                      : "fa fa-trash-o"
                                  } tx-20 mt-1 ml-3 text-muted`}
                                  style={{
                                    cursor: `${
                                      menu.filter((e) => e.new === false)
                                        .length > 1
                                        ? "pointer"
                                        : "not-allowed"
                                    }`,
                                  }}
                                  onClick={() => {
                                    if (data.promotion_code_id === "") {
                                      let tmp = [...menu];

                                      if (value.new === true) {
                                        tmp.pop();
                                        setmenu(tmp);
                                        return;
                                      } else if (value.edit === true) {
                                        tmp[index].edit = !tmp[index].edit;
                                        setmenu(tmp);
                                        return;
                                      }

                                      if (
                                        menu.filter((e) => e.new === false)
                                          .length > 1
                                      ) {
                                        swal({
                                          title: `ต้องการลบเมนู${value.name.label} ?`,
                                          confirmButtonText: "ลบ",
                                          cancelButtonText: "ยกเลิก",
                                          icon: "warning",
                                          buttons: true,
                                          dangerMode: true,
                                        }).then(async (willDelete) => {
                                          if (willDelete) {
                                            let is_delete = deleteMenu(
                                              value.id
                                            );
                                            if (is_delete) {
                                              swal("ลบเสร็จสิ้น !!", {
                                                icon: "success",
                                              });
                                            } else {
                                              swal("เกิดข้อผิดพลาด !!", {
                                                icon: "error",
                                              });
                                            }
                                          }
                                        });
                                      }
                                    } else {
                                      alert("มีการใช้โปรโมชั่นแก้ไขเมนูไม่ได้");
                                    }
                                  }}
                                ></i>
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                            </>
                          )}
                        </tr>
                      );
                    })}

                    {data.status === 1 ||
                    data.status === 2 ||
                    data.status === 3 ||
                    data.status === 4 ? (
                      <tr>
                        <td
                          className="new-row"
                          colSpan="7"
                          onClick={() => {
                            if (data.promotion_code_id === "") {
                              if (menu.length > 0) {
                                if (menu[menu.length - 1].new !== true) {
                                  let tmp = [...menu];
                                  tmp.push({
                                    id: "",
                                    name: "",
                                    price: 0,
                                    remark: "",
                                    edit: true,
                                    new: true,
                                  });
                                  setmenu(tmp);
                                }
                              } else {
                                let tmp = [...menu];
                                tmp.push({
                                  id: "",
                                  name: "",
                                  price: 0,
                                  remark: "",
                                  edit: true,
                                  new: true,
                                });
                                setmenu(tmp);
                              }
                            } else {
                              alert("มีการใช้โปรโมชั่นแก้ไขเมนูไม่ได้");
                            }
                          }}
                        >
                          + เพิ่มบริการเสริม
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td className="new-row" colSpan="7"></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* </div> */}

              <div className="row" style={{ textAlign: "right" }}>
                <div className="col-12 border-bottom pt-2 pb-2">
                  <span className="h5">
                    ค่าขนส่ง ฿
                    {data.car_service_cost
                      ? data.car_service_cost.toLocaleString()
                      : "0"}
                  </span>
                </div>
                <div className="col-12 border-bottom pt-2 pb-2">
                  <span className="h5">
                    ค่าบริการเสริม ฿
                    {data.car_service_addon_cost
                      ? data.car_service_addon_cost.toLocaleString()
                      : "0"}
                    {/* {data.is_promotion_service ? `(โปรโมชั่น)` : ""} */}
                  </span>
                </div>
                {data.promotion_discount > 0 ? (
                  <div className="col-12 border-bottom pt-2 pb-2">
                    <span className="h5">
                      โปรโมชั่น {data.promotion_code ? data.promotion_code : ""}{" "}
                      ฿
                      {data.promotion_discount
                        ? data.promotion_discount.toLocaleString()
                        : "0"}
                    </span>
                  </div>
                ) : null}
                <div className="col-12 border-bottom pt-2 pb-2">
                  <span className="h5">ระยะทาง(~ {data.distance}km) </span>
                </div>
                <div className="col-12 border-bottom pt-2 pb-2">
                  <span className="h5">
                    เก็บเงินสด ฿{data.total ? data.total.toLocaleString() : "0"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row ml-2 mb-4">
              {data.status === 1 ||
              data.status === 2 ||
              data.status === 3 ||
              data.status === 4 ? (
                <button
                  className="btn btn-outline-primary ml-3 "
                  onClick={() => {
                    swal({
                      title: `ต้องการยกเลิกออเดอร์หรือไม่ ?`,
                      confirmButtonText: "ใช่",
                      cancelButtonText: "ไม่",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async (willDelete) => {
                      if (willDelete) {
                        cancelOrder(data.id);
                      }
                    });
                  }}
                >
                  ยกเลิกออเดอร์
                </button>
              ) : (
                ""
              )}

              {data.status === 1 || data.status === 4 ? (
                <button
                  className="btn btn-outline-primary ml-2 "
                  data-toggle="modal"
                  data-target={`.assign-order`}
                  onClick={() => {
                    setselect_order({ value: data.id, label: data.order_no });
                    setorder_option([{ value: data.id, label: data.order_no }]);
                  }}
                >
                  จัดส่ง/มอบหมาย
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className="modal  assign-order" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>มอบหมายงาน</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3 pt-2">
                  <label htmlFor="add_name">คำสั่งซื้อ Order No.</label>
                </div>
                <div className="col-lg-8">
                  <Select
                    value={select_order}
                    options={order_option}
                    placeholder={"เลือก..."}
                    isDisabled={true}
                    // isMulti
                    // closeMenuOnSelect={false}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3 pt-2">
                  <label htmlFor="add_name">พนักงานขับรถ</label>
                </div>

                <div className="col-lg-8">
                  <Select
                    value={select_rider}
                    onChange={async (e) => {
                      setselect_rider(e);
                    }}
                    options={rider_option}
                    placeholder={"เลือก..."}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-11">
                  <div className="row">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                    >
                      ยกเลิก
                    </button>
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-primary  mr-2 "
                      onClick={async () => {
                        if (select_order && select_rider) {
                          let result = await assignOrder(
                            select_rider.value,
                            select_order.value
                          );

                          if (result) {
                            getOrder();
                            getOrderLog();
                            setselect_order("");
                            setselect_rider("");
                            swal("หมอบหมายงานเสร็จสิ้น !!", {
                              icon: "success",
                            });
                          } else {
                            swal("เกิดข้อผิดพลาด !!", {
                              icon: "error",
                            });
                          }
                        }
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalShowImage img={img} />

      <div className="modal  show-map" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800, height: "100%" }}>
            <div className="modal-header">
              <span>แผนที่</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <MyMapComponent point={point} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
