import React, { useEffect, useState, useRef } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import swal from "sweetalert";
import { header_token, api } from "../../config";
import Axios from "axios";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

export const OrderCarReceive = (props) => {
  const checkAllRef = useRef(null);

  const [riderloading, setriderloading] = useState(false);

  const [default_data, setdefault_data] = useState([]);
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);

  const [id, setid] = useState("");
  const [option_area, setoption_area] = useState([]);
  const [filter_area, setfilter_area] = useState("");
  const [filter_order, setfilter_order] = useState("");
  const [filter_name, setfilter_name] = useState("");

  const [select_order, setselect_order] = useState("");
  const [select_rider, setselect_rider] = useState("");
  const [order_option, setorder_option] = useState([]);
  const [rider_option, setrider_option] = useState([]);

  const assignOrder = async (rider_id, order_id) => {
    try {
      let send_data = {
        order_id: order_id,
        rider_id: rider_id,
      };

      let res = await Axios.post(
        `${api}/admin/order/assign/`,
        send_data,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const cancelOrder = async (order_id) => {
    try {
      let res = await Axios.get(
        `${api}/admin/order/${order_id}/cancel`,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getDataOnlineRider = async () => {
    setriderloading(true);
    try {
      let send_data = {
        status: 1,
        // team_id: filter_team.value,
        role: "rider",
        available: "online",
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${1}&limit=${9999999}`,
        send_data,
        header_token
      );
      // console.log(data);
      let tmp = [];
      data.users.map((val) => {
        tmp.push({ value: val.id, label: `${val.user_id} ${val.full_name}` });
        return;
      });

      setrider_option(tmp);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setriderloading(false);
  };

  useEffect(() => {
    if (props.data) {
      let tmp = [];
      props.data.map((val) => {
        tmp.push({ value: val.id, label: val.order_no });
        return;
      });
      setorder_option(tmp);

      setdefault_data(props.data);
      setdata(props.data);
      setoption_area(props.optionArea);

      let cal_page_count = Math.ceil(props.data.length / limit);
      setpage_count(cal_page_count);
    }
  }, [props]);

  useEffect(() => {
    if (default_data.length > 0) {
      let tmp = default_data;

      if (filter_area.value) {
        tmp = default_data.filter(
          (v) => v.restaurant.area.id === filter_area.value
        );
      }

      if (id) {
        tmp = tmp.filter((v) => v.order_no.includes(id));
      }

      if (filter_name) {
        tmp = tmp.filter((v) =>
          v.restaurant.name_th.toLowerCase().includes(filter_name.toLowerCase())
        );
      }

      setdata(tmp);
      let cal_page_count = Math.ceil(tmp.length / limit);
      setpage_count(cal_page_count);
    }
  }, [filter_area, id, filter_name]);

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h4 className="mb-4 mt-2">ทั้งหมด {data.length} รายการ</h4>
              </div>

              <div className="form-row align-items-center mb-2 mr-2 ">
                {/* <div className=" mr-2" style={{ width: 200 }}>
                  <span className="tx-13 text-muted">ชื่อร้านอาหาร</span>
                  <input
                    type="text"
                    className="form-control- global_filter"
                    placeholder="ชื่อร้าน"
                    value={filter_name}
                    onChange={(e) => setfilter_name(e.target.value)}
                  />
                </div> */}

                <div className=" mr-2" style={{ width: 200 }}>
                  <span className="tx-13 text-muted">Order Id</span>
                  <input
                    type="text"
                    className="form-control- global_filter"
                    id="global_filter"
                    placeholder="ID"
                    value={id}
                    onChange={(e) => setid(e.target.value)}
                  />
                </div>

                <div className="mr-1" style={{ width: 150 }}>
                  <span className="tx-13 text-muted">พื้นที่</span>
                  <Select
                    value={filter_area}
                    onChange={(e) => {
                      setfilter_area(e);
                    }}
                    options={option_area}
                    placeholder={"พื้นที่.."}
                  />
                </div>

                <div className="col ml-1">
                  <div className="row">
                    <div style={{ width: 120 }} className="mr-2">
                      <span className="tx-13 text-muted">ดำเนินการ</span>
                      <Select
                        value={filter_order}
                        onChange={(e) => {
                          setfilter_order(e);
                        }}
                        options={[
                          { value: "assign", label: "มอบหมายงาน" },
                          { value: "cancel", label: "ยกเลิก" },
                        ]}
                        placeholder={"เลือก..."}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    data-toggle="modal"
                    data-target={`${
                      filter_order.value === "assign"
                        ? data.filter((e) => e.checked === true).length !== 0
                          ? ".assign-order"
                          : ""
                        : ""
                    }`}
                    className="btn btn-primary mr-2 "
                    style={{ height: 42, marginTop: 20 }}
                    onClick={async () => {
                      if (
                        filter_order.value &&
                        data.filter((e) => e.checked === true).length !== 0
                      ) {
                        if (filter_order.value === "assign") {
                          let tmp = [];
                          data.forEach((value) => {
                            if (value.checked === true) {
                              tmp.push({
                                value: value.id,
                                label: value.order_no,
                              });
                            }
                          });
                          setselect_order(tmp);

                          await getDataOnlineRider();
                        } else if (filter_order.value === "cancel") {
                          swal({
                            title: `ต้องการยกเลิกออเดอร์ที่เลือกหรือไม่ ?`,
                            confirmButtonText: "ใช่",
                            cancelButtonText: "ไม่",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then(async (willDelete) => {
                            if (willDelete) {
                              let sending = [];
                              data.forEach((value) => {
                                if (value.checked === true) {
                                  sending.push(cancelOrder(value.id));
                                }
                              });

                              let is_success = true;
                              await Promise.all(sending).then((res) => {
                                console.log(res);
                                if (
                                  res.filter((e) => e === true).length === 0 ||
                                  res.length === 0
                                )
                                  is_success = false;
                              });

                              if (is_success) {
                                swal("ยกเลิกเสร็จสิ้น !!", {
                                  icon: "success",
                                });
                              } else {
                                swal("เกิดข้อผิดพลาด !!", {
                                  icon: "error",
                                });
                              }
                            }
                          });
                        }
                      } else {
                        alert("เลือกตัวดำเนินการ");
                        return;
                      }
                    }}
                  >
                    ดำเนินการ
                  </button>
                </div>
              </div>
            </div>

            <div className="sticky-div">
              <table id="dataTable" className="table sticky-table">
                <thead>
                  <tr>
                    <th style={{ width: 30 }} className="sticky-head">
                      <input
                        type="checkbox"
                        className="selectAll "
                        name="selectAll"
                        defaultChecked={false}
                        ref={checkAllRef}
                        onClick={(e) => {
                          let tmp = [...data];
                          let _tmp = [];
                          tmp
                            .slice(
                              (current_page - 1) * limit,
                              current_page * limit
                            )
                            .forEach((value, index) => {
                              value.checked = checkAllRef.current.checked;
                              _tmp.push(value);
                            });

                          setdata(tmp);
                        }}
                      />
                    </th>
                    <th style={{ width: 150 }} className="sticky-head">
                      Order No.
                    </th>
                    <th style={{ width: 120 }}>เวลา</th>
                    <th style={{ width: 150 }}>การจัดส่ง</th>
                    <th style={{ width: 150 }}>บริการ</th>
                    <th style={{ width: 300 }}>ต้นทาง</th>
                    <th style={{ width: 300 }}>ปลายทาง</th>
                    <th style={{ width: 150 }}>ผู้รับงาน</th>
                    <th style={{ width: 80 }}>ค่าขนส่ง</th>
                    <th style={{ width: 120 }}>ค่าบริการเสริม</th>
                    <th style={{ width: 80 }}>รวม</th>
                    <th style={{ width: 120 }}>ระยะทาง</th>
                    <th style={{ width: 150 }}>สถานะ</th>
                    <th style={{ width: 120 }}>ชำระ</th>
                    <th style={{ width: 150 }}>รายละเอียด</th>
                  </tr>
                </thead>
                <tbody>
                  {data
                    .slice((current_page - 1) * limit, current_page * limit)
                    .map((value, index) => {
                      if (value.order_no.includes(id)) {
                        return (
                          <tr
                            key={index}
                            style={{
                              backgroundColor: value.checked ? "#e8eaed " : "",
                            }}
                          >
                            <td>
                              <input
                                type="checkbox"
                                checked={value.checked || ""}
                                onChange={(e) => {
                                  let tmp = [...data];
                                  data.forEach((vl, idx) => {
                                    if (vl.id === value.id) {
                                      tmp[idx].checked = !tmp[idx].checked;
                                      setdata(tmp);
                                    }
                                  });
                                }}
                              />
                            </td>
                            <td
                              className="sticky-col"
                              style={{
                                backgroundColor: value.checked
                                  ? "#e8eaed "
                                  : "",
                              }}
                            >
                              <a
                                href="#"
                                tabIndex={0}
                                onClick={() =>
                                  props.pushToLink(
                                    `/car_service_order/detail/${value.id}`
                                  )
                                }
                              >
                                {value.order_no}
                              </a>
                            </td>
                            <td>
                              <i className="fa fa-clock-o mr-1"></i>
                              {moment(value.created_at).format("LT")}
                            </td>
                            <td>
                              {value.type === 0 ? (
                                <span className="badge badge-outline-warning badge-pill">
                                  {moment(value.due_date).format("DD/MM/YYYY")}{" "}
                                  {moment(value.due_date).format("LT")}
                                </span>
                              ) : (
                                <span className="badge badge-outline-success badge-pill">
                                  สังทันที
                                </span>
                              )}
                            </td>

                            <td>{value.car_service_name}</td>
                            <td>
                              {value.car_service_start_point.address}
                              <br />
                              {value.phone_number}
                            </td>
                            <td>
                              {value.car_service_destinations
                                ? value.car_service_destinations.map(
                                    (v, inx) => (
                                      <div key={inx}>
                                        {value.car_service_destinations
                                          .length !== 1
                                          ? `(${inx + 1})`
                                          : null}{" "}
                                        {v.address}
                                      </div>
                                    )
                                  )
                                : null}
                            </td>
                            <td>
                              {value.user.full_name}
                              <br />
                              {value.user.phone_number}
                            </td>
                            <td>฿{value.car_service_cost}</td>
                            <td>฿{value.car_service_addon_cost}</td>
                            <td>฿{value.total}</td>
                            <td>~ {value.distance}km</td>
                            <td>
                              {(() => {
                                if (value.type === 0) return "ออเดอร์ล่วงหน้า";

                                switch (value.status) {
                                  case 0:
                                    return "ยกเลิก";
                                  case 1:
                                    return "ออเดอร์ใหม่";
                                  case 2:
                                    return "พนักงานรับออเดอร์";
                                  case 3:
                                    return "ดำเนินการ";
                                  case 4:
                                    return "กำลังจัดส่ง";
                                  case 5:
                                    return "ส่งสำเร็จ";
                                  case 6:
                                    return "ส่งไม่สำเร็จ";

                                  default:
                                    break;
                                }
                              })()}
                            </td>
                            <td>{value.payment_method}</td>
                            <td>
                              <a
                                href="#"
                                tabIndex={0}
                                onClick={() =>
                                  props.pushToLink(
                                    `/car_service_order/detail/${value.id}`
                                  )
                                }
                                className="btn btn-outline-primary btn-sm"
                              >
                                รายละเอียด
                              </a>
                            </td>
                          </tr>
                        );
                      }
                      return;
                    })}
                </tbody>
              </table>
            </div>
            <div className="mt-2" style={{ float: "right" }}>
              <ReactPaginate
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                breakLabel={<span className="gap">...</span>}
                pageCount={page_count}
                onPageChange={(e) => setcurrent_page(e.selected + 1)}
                forcePage={current_page - 1}
                containerClassName={"pagination"}
                previousLinkClassName={"previous_page"}
                nextLinkClassName={"next_page"}
                disabledClassName={"disabled"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="showModdal" className="modal  assign-order" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>มอบหมายงาน</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3 pt-2">
                  <label htmlFor="add_name">คำสั่งซื้อ Order No.</label>
                </div>
                <div className="col-lg-8">
                  <Select
                    value={select_order}
                    onChange={async (e) => {
                      setselect_order(e);
                    }}
                    options={order_option}
                    placeholder={"เลือก..."}
                    isMulti
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3 pt-2">
                  <label htmlFor="add_name">พนักงานขับ</label>
                </div>

                <div className="col-lg-8">
                  <LoadingMask loading={riderloading} text={"loading..."}>
                    <Select
                      value={select_rider}
                      onChange={async (e) => {
                        setselect_rider(e);
                      }}
                      options={rider_option}
                      placeholder={"เลือก..."}
                    />
                  </LoadingMask>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-11">
                  <div className="row">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                    >
                      ยกเลิก
                    </button>
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-primary  mr-2 "
                      onClick={async () => {
                        if (select_order && select_rider) {
                          let send = [];
                          select_order.forEach((value) => {
                            send.push(
                              assignOrder(select_rider.value, value.value)
                            );
                          });

                          let is_success = true;
                          await Promise.all(send).then((res) => {
                            if (
                              res.filter((e) => e === true).length === 0 ||
                              res.length === 0
                            )
                              is_success = false;
                          });

                          if (is_success) {
                            props.refresh();
                            setselect_order("");
                            setselect_rider("");
                            swal("หมอบหมายงานเสร็จสิ้น !!", {
                              icon: "success",
                            });
                          } else {
                            swal("เกิดข้อผิดพลาด !!", {
                              icon: "error",
                            });
                          }
                        }
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
