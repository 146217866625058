import React from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import { CarServiceIndex } from "./CarServiceIndex";

export const CarServiceHead = () => {
  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">บริการเรียกรถ</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                บริการขนส่ง
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link"}
                activeClassName="active"
                to="/car_service"
              >
                บริการเรียกรถ
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link"}
                activeClassName="active"
                to="/car_service/add"
              >
                เพิ่มบริการเรียกรถ
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Switch>
        <Route exact path={`/promotion_code`}>
          <CarServiceIndex />
        </Route>

        <Route exact path={`/promotion_code/add`}>
          {/* <PromotionCodeAdd /> */}
        </Route>
      </Switch>
    </div>
  );
};
