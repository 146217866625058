import React, { useState, useEffect } from "react";
import moment from "moment";
import Select from "react-select";
import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/th";
import { api, header_token } from "../../config";
import { NavLink, useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { ExportSaleBillShippingData } from "./ExportSaleBillShippingData";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const SaleBillShippingIndex = () => {
  const { push } = useHistory();
  const [loading, setloading] = useState(false);
  const [summary_loading, setsummary_loading] = useState(false);

  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [total_sales, settotal_sales] = useState(0);
  const [total_commission_sales, settotal_commission_sales] = useState(0);
  const [total_addon, settotal_addon] = useState(0);
  const [total_commission_addon, settotal_commission_addon] = useState(0);
  const [total_order, settotal_order] = useState(0);

  const [option_area, setoption_area] = useState([]);
  const [filter_area, setfilter_area] = useState("");
  const [filter_id, setfilter_id] = useState("");
  const [filter_name, setfilter_name] = useState("");
  const [filter_from, setfilter_from] = useState(new Date());
  const [filter_to, setfilter_to] = useState(new Date());

  const [export_loading, setexport_loading] = useState(false);
  const [export_data, setexport_data] = useState([]);
  const [trigger, settrigger] = useState(false);

  const getDataInit = async () => {
    setsummary_loading(true);
    try {
      let start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");

      // let send_data = {
      //   end_date: end,
      //   start_date: start,
      // };
      let send_data = {
        end_date: end,
        order_no: filter_id,
        restaurant_name: filter_name,
        start_date: start,
        status: [5],
        area_id: filter_area.value ? filter_area.value : "",
        service: ["car"],
      };
      // console.log("send", send_data);
      let { data } = await Axios.post(
        `${api}/admin/order-car-service/summary/filter`,
        send_data,
        header_token
      );

      // console.log("data", data);
      settotal_sales(data.total_cost);
      settotal_commission_sales(data.total_commission_cost);
      settotal_addon(data.total_addon_cost);
      settotal_commission_addon(data.total_commission_addon_cost);
      settotal_order(data.total_count);
    } catch (error) {
      console.log(error);
      console.log(error.response);
      setpage_count(0);
      settotal_count(0);
      setdata([]);
      settotal_sales(0);
      settotal_commission_sales(0);
      settotal_addon(0);
      settotal_commission_addon(0);
      settotal_order(0);
    }
    setsummary_loading(false);
  };

  const getDataExport = async () => {
    setexport_loading(true);
    try {
      let start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        end_date: end,
        order_no: "",
        start_date: start,
        status: [5],
        area_id: filter_area.value ? filter_area.value : "",
        service: ["car"],
      };

      // console.log("send", send_data);
      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${1}&limit=${99999999}&sort=${-1}`,
        send_data,
        header_token
      );

      // console.log("data", data);
      if (data.orders !== null) {
        let _export = [];
        data.orders.forEach((value) => {
          let re;
          switch (value.status) {
            case 0:
              re = "ยกเลิก";
              break;
            case 1:
              re = "ออเดอร์ใหม่";
              break;
            case 2:
              re = "พนักงานรับออเดอร์";
              break;
            case 3:
              re = "ดำเนินการ";
              break;
            case 4:
              re = "กำลังจัดส่ง";
              break;
            case 5:
              re = "ส่งสำเร็จ";
              break;
            case 6:
              re = "ส่งไม่สำเร็จ";
              break;
            default:
              break;
          }
          if (value.type === 0) {
            re = "ออเดอร์ล่วงหน้า";
          }
          let _addon = "";
          if (value.car_service_addons) {
            value.car_service_addons.map((v, i) => {
              _addon += `${v.name} ${
                value.car_service_addons.length !== i + 1 ? "," : null
              }`;
            });
          }
          _export.push({
            order_no: value.order_no,
            order_time: `${moment(value.created_at).format(
              "DD/MM/YYYY"
            )} ${moment(value.created_at).format("LT")}`,
            delivery:
              value.type === 0
                ? `${moment(value.due_date).format("DD/MM/YYYY")}  ${moment(
                    value.due_date
                  ).format("LT")}`
                : "สังทันที",
            car_service_name: `${value.car_service_name}`,
            car_service_addons: _addon,
            car_service_cost: `${value.car_service_cost.toLocaleString("en", {
              maximumFractionDigits: 0,
            })}`,
            car_service_percentage_commission_distance: `${(
              value.car_service_cost *
              (value.car_service_percentage_commission_distance / 100)
            ).toLocaleString("en", {
              maximumFractionDigits: 0,
            })}`,
            car_service_addon_cost: value.car_service_addon_cost.toLocaleString(
              "en",
              {
                maximumFractionDigits: 0,
              }
            ),
            car_service_addon_cost_commission:
              (value.car_service_addon_commission
                ? value.car_service_addon_commission
                : 0
              ).toLocaleString("en", {
                maximumFractionDigits: 0,
              }),
            distance: `~ ${value.distance}km`,
            status: re,
          });
        });
        // console.log("_export", _export);
        await setexport_data(_export);
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
    setexport_loading(false);
  };

  const getData = async () => {
    setloading(true);
    try {
      let start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        end_date: end,
        order_no: filter_id,
        restaurant_name: filter_name,
        start_date: start,
        status: [5],
        area_id: filter_area.value ? filter_area.value : "",
        service: ["car"],
      };

      // console.log("send", send_data);
      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${current_page}&limit=${limit}&sort=${-1}`,
        send_data,
        header_token
      );

      // console.log("data", data);
      if (data.orders !== null) {
        let cal_page_count = Math.ceil(data.meta.total_count / limit);
        setpage_count(cal_page_count);
        settotal_count(data.meta.total_count);

        setdata(data.orders);
      } else {
        setpage_count(0);
        settotal_count(0);
        setdata([]);
        // settotal_sales(0);
        // settotal_commission_sales(0);
        // settotal_addon(0);
        // settotal_commission_addon(0);
        // settotal_order(0);
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
    setloading(false);
  };

  const getOptionArea = async () => {
    try {
      let { data } = await Axios.get(`${api}/area`);
      // console.log(data);
      let tmp = [];
      tmp.push({ value: "", label: "ทั้งหมด" });

      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_area(tmp);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getDataInit();
    getData();
    getOptionArea();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getDataInit();
    getData();
  }, [filter_from, filter_to, filter_area, filter_id, filter_name]);

  useEffect(() => {
    getData();
  }, [current_page]);

  // useEffect(() => {
  //   setcurrent_page(1);
  //   getData();
  // }, [filter_id, filter_name]);

  // useEffect(() => {
  //   getDataInit();
  //   getData();
  //   setcurrent_page(1);
  // }, [filter_from, filter_to]);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-3">เรียกรถ</h4>
            <div className="d-none d-md-flex mt-2">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าหลัก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">เรียกรถ</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-12">
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li className="nav-item">
            <NavLink
              exact
              className={"nav-link"}
              activeClassName="active"
              to={`/sale_bill`}
            >
              อาหาร
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              className={"nav-link "}
              activeClassName="active"
              to={`/sale_bill`}
            >
              เรียกรถ
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              className={"nav-link "}
              activeClassName="active"
              to={`/sale_bill/service`}
            >
              บริการ
            </NavLink>
          </li>
        </ul>
      </div> */}

      <div className="row">
        <h4 className="mb-4  ml-3" style={{ marginTop: 0 }}>
          {moment().format("LL")}
        </h4>

        <div className="ml-auto mr-2 mb-2" style={{ float: "right" }}>
          <p className="tx-13 text-muted mb-2">ค้นหาจากช่วงเวลา</p>
          <div className="InputFromTo ">
            <DayPickerInput
              value={filter_from}
              onDayChange={(e) => {
                setfilter_from(e);
                if (!filter_to) {
                  setfilter_to(e);
                }
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
            />{" "}
            ถึง{" "}
            <span className="InputFromTo-to">
              <DayPickerInput
                value={filter_to}
                onDayChange={(e) => {
                  setfilter_to(e);
                  if (!filter_from) {
                    setfilter_from(e);
                  }
                }}
                overlayComponent={CustomOverlay}
                placeholder={"วัน/เดือน/ปี"}
                format="L"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  locale: "th",
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </span>
            <button
              type="button"
              className="btn btn-primary mr-2 ml-2"
              style={{ marginTop: -2, padding: `5px 10px 5px 10px` }}
              onClick={async () => {
                if (!trigger && !export_loading) {
                  await getDataExport();
                  await settrigger(true);
                }
              }}
            >
              {export_loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
              {` `}Export
            </button>
            <ExportSaleBillShippingData
              data={export_data}
              trigger={trigger}
              settrigger={settrigger}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              {/* <div className="row mt-4 mb-4"> */}
              <div className="row mt-4">
                <div className="col-12">
                  <div className="d-flex flex-wrap mb-2">
                    <div className="mr-4">
                      <h2 className="mb-1 text-success ">
                        {summary_loading ? (
                          <span
                            className="spinner-border "
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          `฿${total_sales.toLocaleString()}`
                        )}
                      </h2>
                      <p className="text-muted mb-0 tx-13">ค่าขนส่งทั้งหมด</p>
                    </div>

                    <div className="mr-4">
                      <h2 className="mb-1  ">
                        {summary_loading ? (
                          <span
                            className="spinner-border "
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          `฿${total_commission_sales.toLocaleString("en", {
                            maximumFractionDigits: 0,
                          })}`
                        )}
                      </h2>
                      <p className="text-muted mb-0 tx-13">
                        ค่าคอมมิชชั่นค่าขนส่ง
                      </p>
                    </div>

                    <div className="mr-4">
                      <h2 className="mb-1  ">
                        {summary_loading ? (
                          <span
                            className="spinner-border "
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          `฿${total_addon.toLocaleString()}`
                        )}
                      </h2>
                      <p className="text-muted mb-0 tx-13">ค่าบริการเสริม</p>
                    </div>

                    <div className="mr-4">
                      <h2 className="mb-1  ">
                        {summary_loading ? (
                          <span
                            className="spinner-border "
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          `฿${total_commission_addon.toLocaleString("en", {
                            maximumFractionDigits: 0,
                          })}`
                        )}
                      </h2>
                      <p className="text-muted mb-0 tx-13">
                        ค่าคอมมิชชั่นบริการเสริม
                      </p>
                    </div>

                    <div className="mr-4">
                      <h2 className="mb-1  ">
                        {summary_loading ? (
                          <span
                            className="spinner-border "
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          `${total_order.toLocaleString()}`
                        )}
                      </h2>
                      <p className="text-muted mb-0 tx-13">จำนวนรายการ</p>
                    </div>
                  </div>

                  {/* <div className="visitors-chart-wrapper mt-4">
                    <Line
                      data={line_data}
                      height={80}
                      options={{
                        legend: { position: "top", fullWidth: true },
                      }}
                    />
                  </div> */}
                </div>
              </div>

              {/* <div className="row mt-4"> */}
              <div className="row ">
                <div className="col-12 mb-2 mt-4">
                  <form>
                    <div className="form-row align-items-center mb-2">
                      <h4 className="mt-4 ml-1">
                        ทั้งหมด {total_count} รายการ
                      </h4>
                      {/* <div className="col-sm-2 mr-1 ml-auto">
                        <span>ชื่อร้าน</span>
                        <input
                          type="text"
                          className="form-control- global_filter"
                          id="global_filter"
                          placeholder="ชื่อร้านอาหาร"
                          value={filter_name}
                          onChange={(e) => setfilter_name(e.target.value)}
                        />
                      </div> */}
                      <div className="col-sm-2 mr-1 ml-auto">
                        <span>Order Id</span>
                        <input
                          type="text"
                          className="form-control- global_filter"
                          id="global_filter"
                          placeholder="ออเดอร์ ID"
                          value={filter_id}
                          onChange={(e) => setfilter_id(e.target.value)}
                        />
                      </div>

                      <div className="mr-1" style={{ width: 150 }}>
                        <span className="tx-13 ">พื้นที่</span>
                        <Select
                          value={filter_area}
                          onChange={(e) => {
                            setfilter_area(e);
                          }}
                          options={option_area}
                          placeholder={"พื้นที่.."}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="sticky-div">
                    <table
                      id="dataTable"
                      className="table sticky-table"
                      style={{ width: "100%" }}
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          <th style={{ width: 150 }} className="sticky-head">
                            ออเดอร์ ID
                          </th>
                          <th style={{ width: 200 }}>บริการ</th>
                          <th style={{ width: 250 }}>บริการเสริม</th>
                          <th style={{ width: 80 }}>ค่าขนส่ง</th>
                          <th style={{ width: 180 }}>ค่าคอมมิชชั่นค่าส่ง</th>
                          <th style={{ width: 100 }}>ค่าบริการเสริม</th>
                          <th style={{ width: 180 }}>
                            ค่าคอมมิชชั่นบริการเสริม
                          </th>
                          <th style={{ width: 130 }}>ระยะทาง</th>
                          <th style={{ width: 150 }}>วันที่</th>
                          <th style={{ width: 100 }}>ชำระ</th>
                          <th style={{ width: 130 }}>ดำเนินการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td className="sticky-col">
                                <a
                                  tabIndex={0}
                                  onClick={() =>
                                    push(
                                      `/car_service_order/detail/${value.id}`
                                    )
                                  }
                                >
                                  {value.order_no}
                                </a>
                              </td>
                              <td>{value.car_service_name}</td>
                              <td>
                                {value.car_service_addons
                                  ? value.car_service_addons.map((v, i) => (
                                      <span key={i}>
                                        {v.name}{" "}
                                        {value.car_service_addons.length !==
                                        i + 1
                                          ? ","
                                          : null}
                                      </span>
                                    ))
                                  : null}
                              </td>
                              <td>
                                ฿
                                {value.car_service_cost.toLocaleString("en", {
                                  maximumFractionDigits: 0,
                                })}
                              </td>
                              <td>
                                ฿
                                {(
                                  value.car_service_cost *
                                  (value.car_service_percentage_commission_distance /
                                    100)
                                ).toLocaleString("en", {
                                  maximumFractionDigits: 0,
                                })}
                              </td>
                              <td>
                                ฿
                                {value.car_service_addon_cost.toLocaleString(
                                  "en",
                                  {
                                    maximumFractionDigits: 0,
                                  }
                                )}
                              </td>
                              <td>
                                ฿
                                {value.car_service_addon_commission.toLocaleString(
                                  "en",
                                  {
                                    maximumFractionDigits: 0,
                                  }
                                )}
                              </td>
                              <td>~ {value.distance}</td>
                              <td>
                                {moment(value.created_at).format("DD/MM/YYYY")}{" "}
                                {moment(value.created_at).format("LT")}
                              </td>
                              <td>{value.payment_method}</td>
                              <td>
                                <button
                                  className="btn btn-outline-primary btn-sm mb-1"
                                  onClick={() =>
                                    push(
                                      `/car_service_order/detail/${value.id}`
                                    )
                                  }
                                >
                                  รายละเอียด
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-2" style={{ float: "right" }}>
                    <ReactPaginate
                      previousLabel={"← Previous"}
                      nextLabel={"Next →"}
                      breakLabel={<span className="gap">...</span>}
                      pageCount={page_count}
                      onPageChange={(e) => setcurrent_page(e.selected + 1)}
                      forcePage={current_page - 1}
                      containerClassName={"pagination"}
                      previousLinkClassName={"previous_page"}
                      nextLinkClassName={"next_page"}
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
